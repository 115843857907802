import { Box, Typography } from "@mui/material";
import { default as bannerImage } from "@root/assets/h2hLogo-opacity.png";
import { GridWrapper } from "@root/components/GridWrapper";
import { colors } from "@root/theme/colors";

type BannerProps = {
  headerText: string;
  subheaderComponent: React.ReactNode;
  idForAnchor?: string;
};

const HorizontalImageWrapper = () => (
  <Box sx={{ display: { xs: "none", md: "block" } }}>
    <img src={bannerImage} alt="h2h logo" />
  </Box>
);

export const Banner = ({
  headerText,
  subheaderComponent,
  idForAnchor,
}: BannerProps) => {
  return (
    <Box
      id={idForAnchor}
      sx={{
        width: "100%",
        backgroundColor: colors.primaryMain,
        py: 4,
      }}
    >
      <GridWrapper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <HorizontalImageWrapper />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: "30px", md: "48px" }, px: 3 }}
            >
              {headerText}
            </Typography>
            {subheaderComponent}
          </Box>
          <HorizontalImageWrapper />
        </Box>
      </GridWrapper>
    </Box>
  );
};
