import { Box } from "@mui/material";
import { ForgotPasswordForm } from "@root/components/Forms/ForgotPasswordForm";
import { LeftPart } from "@root/components/LeftPart";
import { RightPart } from "@root/components/RightPart";
import {useState} from "react";
import {Tab} from "@root/components/Sections/Login/types.ts";

export const ForgotPassword = () => {
  const [tab, setTab] = useState<Tab>("client");
  return (
    <Box sx={{ display: "flex" }}>
      <LeftPart tab={tab}/>
      <RightPart isTabs={false} tab={tab} setTab={setTab}>
        <ForgotPasswordForm />
      </RightPart>
    </Box>
  );
};
