import { Box, IconButton, Link, Typography } from "@mui/material";
import { default as H2HLogo } from "@root/assets/h2hLogo.png";
import { GridWrapper } from "@root/components/GridWrapper";
import { Icon, Icons } from "@root/components/Icon";
import { colors } from "@root/theme/colors";
const footerLinks = [
  // {
  //   title: "Funkcje",
  //   link: "#Funkcje",
  // },
  // {
  //   title: "FAQ",
  //   link: "#FAQ",
  // },
  // {
  //   title: "Kontakt",
  //   link: "#Kontakt",
  // },

  {
    title: "Regulamin",
    link: "/assets/regulamin.pdf",
  },
  {
    title: "Polityka prywatności",
    link: "/assets/polityka.pdf",
  },
];

const footerIcons = [
  // {
  //   title: "Twitter",
  //   link: "https://twitter.com/H2Home_PL",
  //   icon: Icons.TwitterIcon,
  // },
  {
    title: "Facebook",
    link: "https://www.facebook.com/h2homePL",
    icon: Icons.FacebookIcon,
  },
  {
    title: "Instagram",
    link: "https://www.instagram.com/h2home.pl/",
    icon: Icons.InstagramIcon,
  },
  // {
  //   title: "LinkedIn",
  //   link: "#",
  //   icon: Icons.LinkedInIcon,
  // },
];

export const Footer = () => {
  const currentYear = new Date(Date.now()).getFullYear();

  return (
    <GridWrapper>
      <Box component="footer" sx={{ my: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: { xs: 2, md: 0 },
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box component="a" href="/#">
            <img src={H2HLogo} alt="h2h logo" width="230px" />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: { xs: 1, md: 4 },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {footerLinks.map((item, index) => (
              <Link
                key={index}
                href={item.link}
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "VisbyCF-Medium",
                  color: colors.secondaryMain,
                  textDecoration: "none",
                }}
              >
                {item.title}
              </Link>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: { xs: "100%", md: "auto" },
              justifyContent: { xs: "space-between", md: "flex-start" },
            }}
          >
            {footerIcons.map((item, index) => (
              <IconButton
                component="a"
                target="_blank"
                rel="norefferer noopener"
                href={item.link}
                key={index}
              >
                <Icon color="secondary" icon={item.icon} />
              </IconButton>
            ))}
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            marginLeft: "auto",
            width: "100%",
            mt: 2,
            margin: { xs: "20px auto 0 auto", md: "0 2 0 auto" },

            // break words
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
          }}
        >
          @{currentYear} - H2Home | Wszelkie prawa zastrzeżone
        </Typography>
      </Box>
    </GridWrapper>
  );
};
