import { Box, Link, Typography } from "@mui/material";

export const ErrorSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        textAlign: "center",
      }}
    >
      <Link href="/">
        <img height={80} src="/src/assets/h2hLogo.png" />
      </Link>
      <Typography variant="h1">Błąd 404</Typography>
      <Typography variant="h1">Ups! Coś poszło nie tak!</Typography>
    </Box>
  );
};
