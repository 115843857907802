import { Box } from "@mui/material";
import { default as piąte1 } from "@root/assets/posts/ankieta.png";
import { default as czwarte1 } from "@root/assets/posts/chat.png";
import { default as jedno1 } from "@root/assets/posts/www_1_1.png";
import { default as jedno2 } from "@root/assets/posts/www_1_2.png";
import { default as jedno3 } from "@root/assets/posts/www_1_3.png";
import { default as drugie1 } from "@root/assets/posts/www_2_1.png";
import { default as drugie2 } from "@root/assets/posts/www_2_2.png";
import { default as trzecie1 } from "@root/assets/posts/www_3_1.png";
import { default as trzecie2 } from "@root/assets/posts/www_3_2.png";
import { default as trzecie3 } from "@root/assets/posts/www_3_3.png";
import { GridWrapper } from "@root/components/GridWrapper";
import { Post } from "@root/components/Post";
import { Post as PostType } from "@root/components/Post/types";

const posts: PostType[] = [
  {
    title: "Zarządzaj projektem ",
    differentColorTitle: "z jednego miejsca",
    firstContent:
      "Nigdy więcej bałaganu i zgubionych dokumentów! Od teraz kosztorysy, moodboady, a nawet komentarze klienta masz zawsze pod ręką, gdziekolwiek jesteś. Twórz galerie inspiracji i wizualizacje projektu, aby w mgnieniu oka udostępnić je inwestorowi lub zespołowi, z którym pracujesz.",
    secondContent:
      "<b>Koniec z przekopywaniem się przez dziesiątki mejli.</b> Teraz wszystko masz w jednym miejscu.",
    img: [jedno1, jedno2, jedno3],
    buttonText: "Zarejestruj się",
    imgPosition: "left",
  },
  {
    title: "Twórz listy zakupowe i kosztorysy ",
    differentColorTitle: "jednym kliknięciem",
    firstContent:
      "Zapomnij o godzinach spędzonych podczas tworzenia list zakupowych i kosztorysów. Dzięki <b><a href='https://chromewebstore.google.com/detail/h2home-extension/ighpdnoohnchfghplmmldgljjkdeioni'>wtyczce</a></b> H2Home możesz je przygotować za pomocą jednego kliknięcia, podczas przeglądania produktów w sieci.",
    secondContent:
      "Dodaj wybrany produkt do listy zakupowej, poczekaj na akceptację klienta, a następnie zaktualizuj kosztorys. <b>Tak, to takie proste!</b>",
    img: [drugie1, drugie2],
    buttonText: "Zarejestruj się",
    imgPosition: "right",
  },
  {
    title: "Działaj szybciej ",
    differentColorTitle: "i skuteczniej!",
    firstContent:
      "Bądź zawsze na bieżąco! Kontroluj postęp prac swojego zespołu, a jednocześnie sprawdzaj komentarze i akcepty klienta w pięciu, ośmiu, a nawet w dwudziestu projektach! Zobacz, które z realizacji prowadzone są zgodnie z planem, a które wymagają większego zaangażowania.",
    secondContent:
      "<b>Efektywniej zarządzaj swoim czasem</b> i szybciej osiągaj zamierzone cele dzięki H2Home.",
    img: [trzecie1, trzecie2, trzecie3],
    buttonText: "Zarejestruj się",
    imgPosition: "left",
  },
  {
    title: "Czat ",
    differentColorTitle: "w Aplikacji H2Home",
    firstContent:
      "Funkcja czatu w H2Home umożliwia prowadzenie całej komunikacji projektowej w jednym miejscu! Główne funkcje czatu to: ",
    secondContent:
      "<ul style='margin-left: 20px;'><li>Bezpośrednie wiadomości: Szybkie wysyłanie wiadomości do klientów.</li><li>System powiadomień: Skuteczne reagowanie na zdarzenia w projekcie.</li><li>Udostępnianie plików: Łatwe przesyłanie dokumentów, zdjęć i innych plików.</li></ul>",
    img: [czwarte1],
    buttonText: "Zarejestruj się",
    imgPosition: "right",
  },
  {
    title: "Ankieta ",
    differentColorTitle: "– Kwestionariusz dla Klienta w H2Home",
    firstContent:
      "Funkcja 'Ankieta' w H2Home pozwala na skuteczne poznanie potrzeb klienta odnośnie nowego wnętrza!",
    secondContent:
      "<ul style='margin-left: 20px;'><li>Tworzenie ankiet: Dodawaj różne typy pytań.</li><li>Łatwe udostępnianie: Przypisuj ankiety do projektów.</li><li>Poznawanie potrzeb klientów: Zbieraj dane do nowego projektu.</li></ul>",
    img: [piąte1],
    buttonText: "Zarejestruj się",
    imgPosition: "left",
  },
];

export const Posts = () => {
  return (
    <GridWrapper>
      <Box>
        {posts.map((post, index) => (
          <Post
            key={index}
            title={post.title}
            differentColorTitle={post.differentColorTitle}
            firstContent={post.firstContent}
            secondContent={post.secondContent}
            buttonText={post.buttonText}
            img={post.img}
            imgPosition={post.imgPosition}
          />
        ))}
      </Box>
    </GridWrapper>
  );
};
