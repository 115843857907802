import {
  Box,
  Button,
  FormHelperText,
  Link,
  Typography,
} from "@mui/material";
import { errorMessages } from "@root/components/Forms/errorMessages";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {red} from "@mui/material/colors";
import {client} from "@root/utils.ts";
import {colors} from "@root/theme/colors.ts";
import { TextField } from "@root/components/TextField";

type FormValues = {
  email: string;
};

type Errors = {
  [key: string]: string;
};

export const EmailConfirmationForm = () => {
    const [submitError, setSubmitError] = useState<string>("");
    const [submitInfo, setSubmitInfo] = useState<string>("");
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
  });
  const [errors, setErrors] = useState<FormValues>({
    email: "",
  });

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(errorMessages.email.patternError)
      .required(errorMessages.email.requiredError),
  });

  const validate = () => {
    schema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setErrors((prevState) => ({
          ...prevState,
          email: "",
        }));
      })
      .catch((err) => {
        const newErrors = err.inner.reduce(
          (acc: Errors, curr: Errors) => ({
            ...acc,
            [curr.path]: curr.message,
          }),
          {}
        );
        setErrors(newErrors);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

    const handleSubmit = async () => {
        validate();
        const resp = await client.post(
            `/Identity/Account/EmailConfirmation?email=${formValues.email}`
        );
    
        if (resp.status === 204) {
            setSubmitError("");
            setSubmitInfo("Na podany adres email został wysłany link aktywacyjny.");
        } else {
            if (resp.data){
                setSubmitError(resp.data);
            } else{
                setSubmitError("Wystąpił błąd podczas generowana linku aktywacyjnego");
            }
        }
    };

  useEffect(() => {
    if (!formValues.email) {
      return;
    }
    validate();
  }, [formValues]);

  return (
    <Box sx={{ width: "100%", marginTop: "60%" }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">Potwierdź e-mail</Typography>
          {!submitError && !submitInfo && <Typography sx={{ color: red }}>
          Podany link do aktywacji e-mail był nieprawidłowy lub wygasł.
        </Typography>}
          <Typography sx={{ color: colors.black40 }}>
              Wpisz swój adres e-mail, aby otrzymać nowy link aktywacyjny.
          </Typography>
          {submitError && (
              <Typography sx={{ color: "red" }}>{submitError}</Typography>
          )}
          {submitInfo && (
              <Typography sx={{ color: "green" }}>{submitInfo}</Typography>
          )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 3,
          width: "100%",
        }}
      >
        <TextField
          type="email"
          value={formValues.email}
          onChange={handleChange}
          name="email"
          placeholder="np. jankowalski@gmail.com"
          label="Adres e-mail"
          variant="outlined"
          sx={{ width: "100%" }}
          error={!!errors.email}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {errors.email && <FormHelperText>{errors.email}</FormHelperText>}

        <Button
          onSubmit={handleSubmit}
          disabled={!!errors.email || !formValues.email}
          variant="contained"
          color="secondary"
          sx={{
            width: "100%",
            color: "white",
            fontFamily: "VisbyCF-ExtraBold",
          }}
        >
          Wyślij link
        </Button>
        <Link href="/login" sx={{ textAlign: "center" }}>
          <Typography variant="body2">Konto zostało już aktywowane? Zaloguj się</Typography>
        </Link>
        <Link href="/register" sx={{ textAlign: "center" }}>
          <Typography variant="body2">
            Nie masz jeszcze konta? Zarejestruj się
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};
