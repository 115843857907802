import { Box } from "@mui/material";

export const PriceOptionWrapper = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        flexWrap: { xs: "wrap", md: "nowrap" },
        gap: 5,
        mb: 4,
      }}
    >
      {...children}
    </Box>
  );
};
