import { Button, Typography } from "@mui/material";
import { CookieConsent as CookieConsentLib } from "react-cookie-consent";
import { CookieConsentProps } from "react-cookie-consent/dist/CookieConsent.props";
import "./CookieConsent.css";

export const CookieConsent = () => {
  return (
    <CookieConsentLib
      buttonText="Zaakceptuj"
      overlayClasses="cookieOverlay"
      containerClasses="cookieContainer"
      buttonClasses="cookieButton"
      ButtonComponent={({ children, ...props }: CookieConsentProps) => (
        <Button
          variant="outlined"
          color="inherit"
          data-cy="cookie-button"
          sx={{
            mr: 2,
          }}
          {...props}
        >
          {children}
        </Button>
      )}
      onAccept={() => console.log("callback")}
    >
      <Typography variant="body2" color="white">
        Ta strona używa plików cookies. Korzystając z niej wyrażasz zgodę na ich
        używanie, zgodnie z aktualnymi ustawieniami przeglądarki. Jeśli chcesz
        dowiedzieć się wiecej zapoznaj się z{" "}
        <a
          style={{ color: "white" }}
          href={"/assets/polityka.pdf"}
          target="_blank"
        >
          Polityką Prywatności.
        </a>
      </Typography>
    </CookieConsentLib>
  );
};
