import { Box } from "@mui/material";
import { useState } from "react";

import { GridWrapper } from "@root/components/GridWrapper";
import { PriceOption } from "./components/PriceOption";
import { PriceOptionWrapper } from "./components/PriceOptionWrapper";
import { Switcher } from "./components/Switcher";

const priceOptions = [
  {
    year: {
      sizeInGb: 2.5,
      optionDescription:
        "Pakiet startowy. Dobry dla początkujących architektów lub osób, które chcą sprawdzić jak działa H2Home. Zmieścisz tu między 15 a 30 projektów w zależności od ich wielkości.",
      price: 0,
      includedInPlan: false,
      promotionPercentage: 0,
    },
    month: {
      sizeInGb: 2.5,
      optionDescription:
        "Pakiet startowy. Dobry dla początkujących architektów lub osób, które chcą sprawdzić jak działa H2Home. Zmieścisz tu między 15 a 30 projektów w zależności od ich wielkości.",
      price: 0,
      includedInPlan: false,
      promotionPercentage: 0,
    },
  },
  {
    year: {
      sizeInGb: 15,
      optionDescription:
        "Jesteś już doświadczonym architektem i masz stały przypływ zleceń. Zmieścisz tu między 90 a 180 projektów w zależności od ich wielkości.",
      price: 191.9,
      includedInPlan: false,
      promotionPercentage: 20,
    },
    month: {
      sizeInGb: 15,
      optionDescription:
        "Jesteś już doświadczonym architektem i masz stały przypływ zleceń. Zmieścisz tu między 90 a 180 projektów w zależności od ich wielkości.",
      price: 19.99,
      includedInPlan: false,
      promotionPercentage: 30,
    },
  },
  {
    year: {
      sizeInGb: 50,
      optionDescription:
        "Zatrudniasz kilku architektów i tworzysz kilka projektów w tym samym czasie. Zmieścisz tu między 300 a 600 projektów w zależności od ich wielkości.",
      price: 383.9,
      includedInPlan: false,
      promotionPercentage: 20,
    },
    month: {
      sizeInGb: 50,
      optionDescription:
        "Zatrudniasz kilku architektów i tworzysz kilka projektów w tym samym czasie. Zmieścisz tu między 300 a 600 projektów w zależności od ich wielkości.",
      price: 39.99,
      includedInPlan: false,
      promotionPercentage: 30,
    },
  },
  {
    year: {
      sizeInGb: 100,
      optionDescription:
        "Prowadzisz duże biuro i prowadzisz wiele projektów w jednym czasie. Zmieścisz tu między 600 a 1200 projektów w zależności od ich wielkości.",
      price: 575.9,
      includedInPlan: false,
      promotionPercentage: 20,
    },
    month: {
      sizeInGb: 100,
      optionDescription:
        "Prowadzisz duże biuro i prowadzisz wiele projektów w jednym czasie. Zmieścisz tu między 600 a 1200 projektów w zależności od ich wielkości.",
      price: 59.99,
      includedInPlan: false,
      promotionPercentage: 30,
    },
  },
];

export type SubscriptionPeriod = "month" | "year";

export const PricingSection = () => {
  const [subscriptionPeriod, setSubscriptionPeriod] =
    useState<SubscriptionPeriod>("month");

  return (
    <GridWrapper>
      <Box
        id="pricing"
        sx={{
          width: "100%",
          scrollMarginTop: "50px",
        }}
      >
        <Switcher
          subscriptionPeriod={subscriptionPeriod}
          setSubscriptionPeriod={setSubscriptionPeriod}
        />
        <PriceOptionWrapper>
          {priceOptions.map((priceOption, index) => (
            <PriceOption
              key={index}
              sizeInGb={priceOption[subscriptionPeriod].sizeInGb}
              optionDescription={
                priceOption[subscriptionPeriod].optionDescription
              }
              price={priceOption[subscriptionPeriod].price}
              includedInPlan={priceOption[subscriptionPeriod].includedInPlan}
              isMonthly={subscriptionPeriod === "month"}
              promotionPercentage={
                priceOption[subscriptionPeriod].promotionPercentage
              }
            />
          ))}
        </PriceOptionWrapper>
      </Box>
    </GridWrapper>
  );
};
