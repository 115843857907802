import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import {useState} from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {TextFieldProps} from "@mui/material/TextField/TextField";

export const TextField = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return <MuiTextField 
      {...props}
      type={props.type === "password" ? (showPassword ? "text" : "password") : props.type}
      InputProps={props.type === "password" ? {
        endAdornment: (
            <InputAdornment position="end">
              <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
        )
      } : props.InputProps}
  />;
};
