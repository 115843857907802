import { Box, Button, FormHelperText, Link, Typography } from "@mui/material";
import { errorMessages } from "@root/components/Forms/errorMessages";
import { TextField } from "@root/components/TextField";
import { colors } from "@root/theme/colors";
import { client } from "@root/utils.ts";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

type FormValues = {
  email: string;
};

type Errors = {
  [key: string]: string;
};

export const ForgotPasswordForm = () => {
  const [submitInfo, setSubmitInfo] = useState<string>("");
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
  });
  const [errors, setErrors] = useState<FormValues>({
    email: "",
  });
  const [submitError, setSubmitError] = useState<string>("");

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(errorMessages.email.patternError)
      .required(errorMessages.email.requiredError),
  });

  const validate = () => {
    schema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setErrors((prevState) => ({
          ...prevState,
          email: "",
        }));
      })
      .catch((err) => {
        const newErrors = err.inner.reduce(
          (acc: Errors, curr: Errors) => ({
            ...acc,
            [curr.path]: curr.message,
          }),
          {}
        );
        setErrors(newErrors);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    validate();
    const resp = await client.post(
      `/Identity/Account/RememberPasswordExt`,
      JSON.stringify({ ...formValues })
    );

    if (resp.status === 204) {
      setSubmitError("");
      setSubmitInfo(
        "Na podany adres email został wysłany link do zmiany hasła."
      );
    } else {
      if (resp.data) {
        setSubmitError(resp.data);
      } else {
        setSubmitError("Wystąpił błąd podczas rejestracji");
      }
    }
  };

  useEffect(() => {
    if (!formValues.email) {
      return;
    }
    validate();
  }, [formValues]);
  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    if (params.has("invalidToken")) {
      setSubmitError("Podany link jest błędny lub token wygasł.");
    }
  }, [location.pathname, location.search]);

  return (
    <Box sx={{ width: "100%", marginTop: "60%" }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">Przypomnij hasło</Typography>
        <Typography sx={{ color: colors.black40 }}>
          Podaj adres email na który wyślemy link do utworzenia nowego hasła do
          Twojego konta.
        </Typography>

        {submitError && (
          <Typography sx={{ color: "red" }}>{submitError}</Typography>
        )}
        {submitInfo && (
          <Typography sx={{ color: "green" }}>{submitInfo}</Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 3,
          width: "100%",
        }}
      >
        <TextField
          type="email"
          value={formValues.email}
          onChange={handleChange}
          name="email"
          placeholder="np. jankowalski@gmail.com"
          label="Adres e-mail"
          variant="outlined"
          sx={{ width: "100%" }}
          error={!!errors.email}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            "data-cy": "email-input",
          }}
        />

        {errors.email && <FormHelperText data-cy="email-error">{errors.email}</FormHelperText>}

        <Button
          data-cy="submit-button"
          onClick={handleSubmit}
          disabled={!!errors.email || !formValues.email}
          variant="contained"
          color="secondary"
          sx={{
            width: "100%",
            color: "white",
            fontFamily: "VisbyCF-ExtraBold",
          }}
        >
          Wyślij link
        </Button>
        <Link data-cy="login-link" href="/login" sx={{ textAlign: "center" }}>
          <Typography variant="body2">Zaloguj się</Typography>
        </Link>
        {/*<Link href="/register" sx={{ textAlign: "center" }}>*/}
        {/*    <Typography variant="body2">*/}
        {/*        Nie masz jeszcze konta? Zarejestruj się*/}
        {/*    </Typography>*/}
        {/*</Link>*/}
      </Box>
    </Box>
  );
};
