import { Box, Typography } from "@mui/material";
import { Accordion } from "@root/components/Accordion";
import { GridWrapper } from "@root/components/GridWrapper";

const accordionDataWithSections = {
  Rejestracja: [
    {
      title: "Jak mogę się zarejestrować jako klient?",
      content:
        "Aby zarejestrować się jako klient, wypełnij formularz rejestracyjny, podając: <ul><li>Adres email (wymagane)</li><li>Imię (wymagane)</li><li>Nazwisko (wymagane)</li><li>Hasło (wymagane)</li><li>Akceptacja regulaminu i polityki prywatności(wymagane)</li></ul> Po wypełnieniu formularza, otrzymasz wiadomość email z linkiem do potwierdzenia adresu email.",
    },
    {
      title: "Jak mogę się zarejestrować jako architekt?",
      content:
        "Aby zarejestrować się jako właściciel biura architektonicznego, wypełnij formularz rejestracyjny, podając: <ul><li>Nazwę firmy (wymagane)</li><li>NIP</li><li>Imię (wymagane)</li><li>Nazwisko (wymagane)</li><li>Hasło (wymagane)</li><li>Akceptacja regulaminu i polityki prywatności(wymagane)</li></ul> Po wypełnieniu formularza, otrzymasz wiadomość email z linkiem do potwierdzenia adresu email.",
    },
  ],
  Biuro: [
    {
      title: "Co zawiera dashboard biura?",
      content:
        "Dashboard biura zawiera skrót informacji o biurze, w tym podgląd do: <ul><li>Wydarzeń</li><li>Projektów</li><li>Zadań</li></ul>Z tego poziomu można dodać nowy projekt, wydarzenie oraz zadanie.",
    },
    {
      title: "Jak mogę dodać nowy projekt?",
      content:
        "Aby dodać nowy projekt, podaj nazwę projektu. Dodatkowe opcje obejmują: <ul><li>Email klienta: Wprowadzenie emaila klienta wyśle zaproszenie do projektu. Jeśli klient nie jest zarejestrowany, otrzyma link do rejestracji.</li><li>Projektant: Wybór z listy pracowników zaproszonych do biura.</li><li>Budżet: Ustalenie kwoty na projekt.</li><li>Waluta: Wybór waluty (PLN, EUR, USD).</li><li>Termin: Ustalenie terminu realizacji projektu.</li></ul>",
    },
    {
      title: "Jak zarządzać zadaniami w biurze?",
      content:
        "Można dodawać zadania, przypisywać je do architektów i projektów oraz ustalać statusy:<ul><li>Schowek</li><li>Do zrobienia</li><li>W realizacji</li><li>Wykonane</li></ul> Zadania mogą być zarządzane za pomocą funkcji 'przeciągnij i upuść'.",
    },
    {
      title: "Jakie funkcje oferuje kalendarz?",
      content:
        "Kalendarz wyświetla wydarzenia w formatach:<ul><li>Miesiąc</li><li>Tydzień</li><li>Dzień</li></ul> Czerwona linia pokazuje dzisiejszy dzień i godzinę. Można dodawać wydarzenia, podając nazwę, opis, datę rozpoczęcia i zakończenia, oraz przypisywać projekty, architektów i pliki.",
    },
    {
      title: "Jakie funkcje oferuje sekcja zespołu?",
      content:
        "Sekcja zespołu pozwala na zarządzanie pracownikami w biurze:<ul><li><b>Dodaj pracownika:</b> Aby dodać pracownika, wypełnij formularz, a następnie email z hasłem tymczasowym zostanie wysłany. Wymagane pola to Imię, Nazwisko i Email.</li></ul>",
    },
    {
      title: "Jakie funkcje oferuje sekcja ankiet?",
      content:
        "Sekcja ankiet umożliwia tworzenie kwestionariuszy dla klientów w celu zebrania informacji o projekcie:<ul><li><b>Dodaj nową ankietę: </b>Aby dodać nową ankietę, wypełnij nazwę formularza oraz tytuł ankiety, następnie wybierz krok, czyli daną sekcję ankiety lub konkretne pytanie, które może mieć trzy formaty: <ul><li>Jednokrotnego wyboru</li><li>Wielokrotnego wyboru</li><li>Pytanie opisowe</li></ul></li><li><b>Przypisz ankietę do projektu: </b>Gdy mamy już stworzoną ankietę, możemy ją podpiąć pod projekt, aby klient mógł ją wypełnić.</li><li><b>Historia wysłania ankiety:  </b>Po dodaniu ankiety do projektu po lewej stronie od ankiety pojawi się strzałka, która po naciśnięciu pokaże historię wysłania ankiet.</li></ul>",
    },
    {
      title: "Jakie funkcje oferuje sekcja dokumentów w biurze?",
      content:
        "Sekcja dokumentów umożliwia przechowywanie i zarządzanie plikami związanymi z działalnością biura:<ul><li><b>Dodaj dokument:</b>Aby dodać dokument, wypełnij formularz, podając nazwę dokumentu, tagi oraz załącz plik. Dokumenty mogą być przypisywane do konkretnych projektów.</li><li><b>Tagowanie dokumentów: </b>Dodawanie tagów ułatwia późniejsze wyszukiwanie i organizację dokumentów.</li><li><b>Przypisywanie dokumentów do projektów: </b>Dokumenty mogą być przypisane do konkretnego projektu, co ułatwia ich odnalezienie i udostępnienie klientom.</li></ul>",
    },
    {
      title: "Jakie funkcje oferuje sekcja produktów w biurze?",
      content:
        "Sekcja produktów pozwala na zarządzanie wszystkimi produktami związanymi z projektami biura:<ul><li><b>Dodaj produkt ręcznie: </b>Wypełnij formularz, podając wymagane informacje, takie jak nazwa produktu, kategoria, tagi, wymiary, cena, waluta, dostawca, numer katalogowy, zdjęcie lub link do zdjęcia dostawcy.</li><li><b>Dodaj produkt poprzez link:  </b>Wklej link do produktu ze sklepu internetowego, a system automatycznie pobierze nazwę, opis i cenę.</li><li><b>Dodaj produkt za pomocą wtyczki: </b>Skorzystaj z wtyczki zintegrowanej z przeglądarką Chrome, aby bezpośrednio dodawać produkty z wybranych sklepów internetowych.</li><li><b>Importuj produkty z pliku Excel: </b> Zaimportuj listę produktów z pliku Excel, zgodnie z dostarczonym szablonem.</li></ul>",
    },
  ],
  Projekty: [
    {
      title: "Jak mogę zarządzać projektami?",
      content:
        "W sekcji projektów można przeglądać i zarządzać: <ul><li>Wizualizacjami pomieszczeń</li><li>Listami zakupowymi</li><li>Inspiracjami</li><li>Dokumentami</li><li>Dyskusjami przypisanymi do wersji pomieszczeń</li><li>Dostępami</li></ul>",
    },
    {
      title: "Jak dodawać nowe pomieszczenia do projektu?",
      content:
        "Aby dodać nowe pomieszczenie, podaj nazwę i dodaj zdjęcia w formatach JPG, PNG lub PDF. Można również prowadzić dyskusje przypisane do konkretnych zdjęć.",
    },
    {
      title: "Jak ukrywać i blokować pomieszczenia w projekcie?",
      content:
        "<ul><li><b>Ukrywanie pokoju: </b>Jeżeli ukryjemy pokój, klient go nie widzi. Produkty przypisane do pokoju również nie są widoczne.</li><li><b>Blokowanie pokoju: </b>Aby zablokować pokój, trzeba zaakceptować lub odrzucić produkty. Odblokować może architekt.</li></ul>",
    },
    {
      title: "Jak zarządzać listą zakupową?",
      content:
        "Lista zakupowa umożliwia dodawanie produktów, zarządzanie nimi oraz eksportowanie listy. Oto szczegółowe przypadki użycia: <ol><li><b>Dodawanie produktów:</b><ul><li><b>Ręcznie: </b>Wprowadź nazwę produktu, opis, ilość, cenę jednostkową oraz przypisz do pomieszczenia.</li><li><b>Poprzez linki: </b>Wklej link do produktu ze sklepu internetowego, a system automatycznie pobierze nazwę, opis i cenę.</li><li><b>Za pomocą wtyczek: </b>Korzystaj z wtyczki zintegrowanej z przeglądarką Chrome, która pozwala na bezpośrednie dodawanie produktów z wybranych sklepów internetowych.</li><li><b>Import z pliku Excel: </b>Zaimportuj listę produktów z pliku Excel, zgodnie z dostarczonym szablonem.</li></ul></li><li><b>Udostępnianie listy zakupowej:</b><ul><li><b>Eksport do PDF: </b>Eksportuj listę zakupową do pliku PDF, aby łatwo udostępnić ją klientowi.</li><li><b>Eksport do Excel: </b>Pobierz listę zakupową w formacie Excel, aby móc ją edytować offline.</li><li><b>Wysyłka emailem: </b>Wyślij listę zakupową bezpośrednio do klienta lub dostawcy za pomocą funkcji wysyłki email.</li></ul></li><li><b>Monitorowanie produktów:</b><ul><li><b>Statusy: </b>Ustalaj statusy produktów.</li><li><b>Dyskusja: </b>Rozmowa może być prowadzona na danym produkcie.</li></ul></li></ol>",
    },
    {
      title: "Czy można importować produkty z innych projektów?",
      content:
        "Tak, można importować produkty z innych projektów. Wybierz projekt oraz produkty, które mają być dodane do wybranego pomieszczenia.",
    },
    {
      title: "Jak eksportować listę zakupową?",
      content:
        "Lista zakupowa może być eksportowana w kilku formatach, co ułatwia jej udostępnienie i edycję: <ul><li><b>Eksport do PDF: </b>Eksportuj listę zakupową do pliku PDF, aby łatwo udostępnić ją klientowi. Wybierz opcję 'Eksport do PDF', a system wygeneruje plik gotowy do pobrania lub wysłania.</li><li><b>Eksport do Excel: </b>Pobierz listę zakupową w formacie Excel, aby móc ją edytować offline. Wybierz opcję 'Eksport do Excel', a system wygeneruje plik, który możesz pobrać i edytować w programie Excel.</li><li><b>Wysyłka emailem: </b>Wyślij listę zakupową bezpośrednio do klienta lub dostawcy za pomocą funkcji wysyłki email. Wybierz opcję 'Wyślij email', podaj adres email odbiorcy i wyślij listę bezpośrednio z systemu.</li></ul> Eksportowana lista zakupowa zawiera wszystkie produkty dodane do projektu, ich opisy, ceny, ilości oraz inne istotne informacje. Możesz również dostosować eksportowane dane, filtrując listę zakupową według określonych kryteriów przed eksportem.",
    },
    {
      title: "Jakie możliwości oferuje sekcja dokumentów?",
      content:
        "Sekcja dokumentów pozwala na przechowywanie i zarządzanie plikami, które mogą być udostępniane klientom. Dokumenty mogą być oznaczane tagami oraz przypisywane do projektów.",
    },
    {
      title: "Jak zaprosić klienta do projektu?",
      content:
        "Aby zaprosić klienta do projektu, wprowadź email klienta w odpowiednie pole podczas tworzenia lub edytowania projektu. Klient otrzyma wiadomość email z zaproszeniem do projektu. Jeśli klient nie jest zarejestrowany, otrzyma link do rejestracji. Po zarejestrowaniu się projekt pojawi się na jego liście projektów.",
    },
    {
      title: "Jak zarządzać dostępami dla klienta?",
      content:
        "Podczas dodawania lub edytowania projektu możesz zarządzać dostępami dla klienta: <ul><li><b>Podgląd: </b>Klient może przeglądać wszystkie informacje i dokumenty związane z projektem.</li><li><b>Edycja: </b>Klient może edytować informacje, dodawać komentarze, pliki oraz uczestniczyć w dyskusjach.</li><li><b>Uprawnienia: </b>Możesz nadawać różne poziomy uprawnień dla klienta, takie jak dostęp do wizualizacji pomieszczeń, list zakupowych, inspiracji, dokumentów i innych zasobów projektu.</li><li><b>Usuwanie dostępu: </b>W każdej chwili możesz usunąć dostęp klienta do projektu lub zmienić jego uprawnienia.</li></ul>",
    },
  ],
  Chat: [
    {
      title: "Jak mogę stworzyć nowy chat?",
      content:
        "Aby stworzyć nowy chat, wybierz z listy pracowników oraz klientów, którzy zostali dodani do projektów. Możesz dodać więcej niż jedną osobę do konwersacji.",
    },
    {
      title: "Czy mogę komunikować się z klientami przez chat?",
      content:
        "Tak, możesz komunikować się zarówno z pracownikami zespołu, jak i klientami za pomocą chatu.",
    },
    {
      title: "Czy mogę tworzyć oddzielne chaty dla różnych projektów?",
      content:
        "Tak, można tworzyć oddzielne chaty dla różnych projektów, zapraszając do nich tylko osoby związane z danym projektem.",
    },
  ],
};

export const AccordionSection = () => {
  return (
    <GridWrapper>
      <Box
        id="faq"
        sx={{
          my: 2,
          width: "80%",
          ml: "auto",
          mr: "auto",
          mb: 10,
          scrollMarginTop: "80px",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            mt: 8,
            mb: 4,
            fontSize: { xs: 30, md: "48px" },
          }}
        >
          Ty pytasz, a my odpowiadamy!
        </Typography>
        <Accordion accordionDataWithSections={accordionDataWithSections} />
      </Box>
    </GridWrapper>
  );
};
