import { Box, Grid } from "@mui/material";
import { default as informations } from "@root/assets/1.svg";
import { default as team } from "@root/assets/2.svg";
import { default as speed_project } from "@root/assets/3.svg";
import { default as communication } from "@root/assets/4.svg";
import { default as documentation } from "@root/assets/5.svg";
import { default as catalog } from "@root/assets/6.svg";
import { default as budget } from "@root/assets/7.svg";
import { default as project_money } from "@root/assets/8.svg";
import { GridWrapper } from "@root/components/GridWrapper";
import { ImagePost } from "@root/components/ImagePost";
import { ImagePostProps } from "@root/components/ImagePost/types";

const imagePosts: ImagePostProps[] = [
  {
    title: "Wszystkie informacje w jednym miejscu",
    content:
      "Miej komplet materiałów dotyczących projektu zawsze pod ręką – od pierwszych wizualizacji, aż po finalne ustalenia z klientem.",
    img: informations,
  },
  {
    title: "Efektywna praca z zespołem",
    content:
      "Dziel się pomysłami z członkami zespołu i współpracownikami. Odpowiadaj na wiadomości i koordynuj prace.",
    img: team,
  },
  {
    title: "Listy zakupowe przyśpieszające projektowanie",
    content:
      "Twórz zestawienia produktów jednym kliknięciem i zatwierdzaj je z klientem online.",
    img: speed_project,
  },
  {
    title: "Sprawna komunikacja z klientem",
    content:
      "Koniec z setkami telefonów. Błyskawicznie potwierdzaj i ustalaj dalsze działania.",
    img: communication,
  },
  {
    title: "Dokumentacja projektu",
    content: "Rób notatki, moodboardy, listy zadań i pokaż je innym.",
    img: documentation,
  },
  {
    title: "Katalog produktów",
    content:
      "Zobacz starannie wyselekcjonowaną grupę produktów z modelami 3D i teksturami do pobrania.",
    img: catalog,
  },
  {
    title: "Przejrzysty kosztorys projektu",
    content:
      "Twórz estetyczne i czytelne kosztorysy podczas planowania działań.",
    img: budget,
  },
  {
    title: "Szczegółowy budżet realizacji",
    content:
      "Planuj wydatki, sprawdzaj dotychczasowe koszty i kontroluj budżet projektu.",
    img: project_money,
  },
];

export const ImagePostsGrid = () => {
  return (
    <Box sx={{ my: 10, scrollMarginTop: "80px" }} id="functions">
      <GridWrapper>
        <Box>
          <Grid container spacing={3} rowSpacing={{ xs: 20, md: 12 }}>
            {imagePosts.map((imagePost, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <ImagePost
                  title={imagePost.title}
                  content={imagePost.content}
                  img={imagePost.img}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </GridWrapper>
    </Box>
  );
};
