import { Box, Button } from "@mui/material";

import { SubscriptionPeriod } from "../../PricingSection";

type SwitcherProps = {
  setSubscriptionPeriod: (subscriptionPeriod: SubscriptionPeriod) => void;
  subscriptionPeriod: SubscriptionPeriod;
};

export const Switcher = ({
  subscriptionPeriod,
  setSubscriptionPeriod,
}: SwitcherProps) => {
  const isMonthly = subscriptionPeriod === "month";

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "30%" },
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
        mb: 4,
      }}
    >
      <Button
        sx={{
          width: "50%",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        color="secondary"
        variant={isMonthly ? "contained" : "outlined"}
        onClick={() => setSubscriptionPeriod("month")}
      >
        co miesiąc
      </Button>
      <Button
        sx={{
          width: "50%",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        color="secondary"
        variant={isMonthly ? "outlined" : "contained"}
        onClick={() => setSubscriptionPeriod("year")}
      >
        co rok
      </Button>
    </Box>
  );
};
