import { Box, Typography } from "@mui/material";
import { colors } from "@root/theme/colors";
import { ImagePostProps } from "./types";

export const ImagePost = ({ title, content, img }: ImagePostProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row", sm: "column" },
        alignItems: "center",
        gap: { xs: "20px", sm: "10px" },
        "& img:first-of-type": {
          width: { xs: "40%", sm: "70%" },
          height: { xs: "auto", sm: "128px" },
        },
      }}
    >
      <img
        src={img}
        alt={`${title}`}
        style={{ marginBottom: "20px", objectFit: "contain", height: "200px" }}
      />
      <Box
        sx={{
          width: "100%",
          height: "60px",
          mb: 2,
        }}
      >
        <Typography sx={{ color: colors.primaryDark }} variant="h6">
          {title}
        </Typography>
        <Typography sx={{ width: "100%" }} variant="body1">
          {content}
        </Typography>
      </Box>
    </Box>
  );
};
