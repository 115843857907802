import { Box, Tooltip, Typography } from "@mui/material";
import { colors } from "@root/theme/colors";

import { Icon, Icons } from "@root/components/Icon";

type PriceOptionType = {
  sizeInGb: number;
  optionDescription: string;
  price: number;
  includedInPlan: boolean;
  isMonthly: boolean;
  promotionPercentage: number;
};

export const PriceOption = ({
  sizeInGb,
  optionDescription,
  price,
  includedInPlan,
  isMonthly,
  promotionPercentage,
}: PriceOptionType) => {
  const isPromoted = promotionPercentage > 0;
  const priceAfterPromotion = price - (price * promotionPercentage) / 100;
  const promotedAndIncludedInPlan = isPromoted && includedInPlan;
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "50%" },
        p: 2,
        pt: 3,
        outline: isPromoted
          ? `1px solid ${colors.primaryMain}`
          : includedInPlan
            ? `1px solid ${colors.gray}`
            : "none",
        borderRadius: 3,
        boxShadow: isPromoted
          ? `0px 0px 10px 0px ${colors.primaryMain}`
          : "none",
        position: "relative",
        // add hover effect\
        transition: "0.3s",
        backgroundColor: { xs: colors.lightGray, md: "transparent" },
        "&:hover": {
          backgroundColor: colors.lightGray,
          transition: "0.3s",
        },
      }}
    >
      {includedInPlan && (
        <Tooltip arrow title={"Aktywny plan"}>
          <Box
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
            }}
          >
            <Icon icon={Icons.CheckIcon} color="primary" />
          </Box>
        </Tooltip>
      )}{" "}
      {!promotedAndIncludedInPlan && isPromoted && (
        <Tooltip arrow title={"Trwająca zniżka"}>
          <Box
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
            }}
          >
            <Icon icon={Icons.NewReleasesIcon} color="primary" />
          </Box>
        </Tooltip>
      )}
      <Typography variant="h6" sx={{ mb: 1 }}>
        {sizeInGb} GB
      </Typography>
      <Typography
        variant="body2"
        color={"text.secondary"}
        sx={{
          height: "130px",
        }}
      >
        {optionDescription}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          mt: { xs: 2, sm: 8, md: 12, lg: 6 },
          position: "relative",
        }}
      >
        {promotionPercentage > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              top: -15,
              left: 0,
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                textDecoration: "line-through",
                mr: 1,
              }}
            >
              {price} zł
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              sx={{
                mr: 1,
                fontWeight: "700",
              }}
            >
              -{promotionPercentage}% zniżki
            </Typography>
          </Box>
        )}
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "2.0rem", md: "1.5rem", lg: "2.0rem" },
          }}
        >
          {priceAfterPromotion.toFixed(2)} zł
        </Typography>
        {price > 0 && (
          <Typography
            variant="body2"
            color={"text.secondary"}
            sx={{
              // break words into new line
              wordBreak: "break-word",
              ml: 1,
              width: isMonthly ? "50px" : "30px",
              lineHeight: "1",
            }}
          >
            {String(isMonthly ? "co miesiąc" : "co rok").toLowerCase()}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">
          Zawiera
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", mt: 2, gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Icon
              fontSize="small"
              color="disabled"
              sx={{ mr: 2 }}
              icon={Icons.CheckCircleIcon}
            />
            <Typography variant="body2" color="text.secondary">
              Wszystkie funkcjonalności H2Home
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Icon
              fontSize="small"
              color="disabled"
              sx={{ mr: 2 }}
              icon={Icons.CheckCircleIcon}
            />
            <Typography variant="body2" color="text.secondary">
              Nieograniczona liczba klientów i pracowników
            </Typography>
          </Box>
          {sizeInGb !== 100 && (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Icon
                fontSize="small"
                color="disabled"
                sx={{ mr: 2 }}
                icon={Icons.CheckCircleIcon}
              />
              <Typography variant="body2" color="text.secondary">
                Po osiągnięciu limitu danych, przejdź na wyższy plan lub usuń
                część danych
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
