import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Accordion as MuiAccordion,
  Typography,
} from "@mui/material";
import { Icon, Icons } from "@root/components/Icon";
import "./Accordion.css";

type AccordionProps = {
  accordionDataWithSections: {
    [key: string]: {
      title: string;
      content: string;
    }[];
  };
};

export const Accordion = ({ accordionDataWithSections }: AccordionProps) => {
  return (
    <Box>
      {Object.keys(accordionDataWithSections).map((section, index) => (
        <MuiAccordion key={index}>
          <AccordionSummary
            expandIcon={<Icon color="primary" icon={Icons.AddBoxIcon} />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            sx={{ py: 2 }}
          >
            <Typography variant="h6">{section}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {accordionDataWithSections[section].map((data, index) => (
                <MuiAccordion key={index}>
                  <AccordionSummary
                    expandIcon={
                      <Icon color="secondary" icon={Icons.AddBoxIcon} />
                    }
                    aria-controls={`panel${index}b-content`}
                    id={`panel${index}b-header`}
                    sx={{ py: 2 }}
                  >
                    <Typography
                      sx={{ pr: { xs: "5px", md: "0" }, fontWeight: "bold" }}
                      dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="accordion-title"
                      dangerouslySetInnerHTML={{
                        __html: data.content
                          .replace(
                            /<a-placeholder-href-form>/g,
                            '<a style="color: black" href="/#Form">'
                          )
                          .replace(
                            /<a-placeholder-href-mailto>/g,
                            '<a style="color: black" href="mailto:kontakt@h2home.pl">'
                          ),
                      }}
                    />
                  </AccordionDetails>
                </MuiAccordion>
              ))}
            </Box>
          </AccordionDetails>
        </MuiAccordion>
      ))}
    </Box>
  );
};
