import { Box } from "@mui/material";
import { ArchitectRegisterForm } from "@root/components/Forms/ArchitectRegisterForm";
import { CustomerRegisterForm } from "@root/components/Forms/CustomerRegisterForm";
import { LeftPart } from "@root/components/LeftPart";
import { RightPart } from "@root/components/RightPart";
import { Tab } from "@root/components/Sections/Login/types";
import { useState } from "react";
import {useLocation} from "react-router-dom";

export const Register = () => {
  const location = useLocation();
  const [tab, setTab] = useState<Tab | undefined>(location.pathname.includes("client") ? "client" : "architect");
  const isClient = tab === "client";

  return (
    <Box sx={{ display: "flex" }}>
      <LeftPart tab={tab} />
      <RightPart tab={tab} setTab={setTab}>
        {isClient ? (
          <CustomerRegisterForm isClient={isClient} />
        ) : (
          <ArchitectRegisterForm isClient={isClient} />
        )}
      </RightPart>
    </Box>
  );
};
