import { Box, Typography } from "@mui/material";
import { default as Logo } from "@root/assets/h2hLogo-transparent.png";
import { LeftPart } from "@root/components/LeftPart";
import { RightPart } from "@root/components/RightPart";
import { Tab } from "@root/components/Sections/Login/types";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { LoginForm } from "../../Forms/LoginForm";

export const Login = () => {
  const [tab, setTab] = useState<Tab>("client");
  const isClient = tab === "client";

  return (
    <Box sx={{ display: "flex" }}>
      <LeftPart tab={tab} />
      <RightPart tab={tab} setTab={setTab} isTabs={false}>
        {!isMobile ? (
          <LoginForm isClient={isClient} />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              padding: "20px",
            }}
          >
            <img
              src={Logo}
              alt="House2Home logo"
              style={{ width: "200px", marginBottom: "20px" }}
            />
            <Typography variant="h5" sx={{ mb: 3 }}>
              Strona nie jest dostępna na urządzeniach mobilnych
            </Typography>
            <Typography variant="body1">
              Aby móc korzystać z aplikacji prosimy o wejście na stronę z pomocą
              komputera
            </Typography>
          </Box>
        )}
      </RightPart>
    </Box>
  );
};
