import { Box, Typography } from "@mui/material";
import { default as consultant } from "@root/assets/consultant.png";
import { FormGroup } from "@root/components/FormGroup";
import { GridWrapper } from "@root/components/GridWrapper";
import { Icon, Icons } from "@root/components/Icon";
import { colors } from "@root/theme/colors";
export const FormSection = () => {
  return (
    <Box
      id="contact"
      sx={{
        width: "100%",
        scrollMarginTop: "80px",
        height: "100%",
        backgroundColor: colors.lightGray,
      }}
    >
      <GridWrapper>
        <Typography
          variant="h1"
          sx={{
            mt: 6,
            mb: 8,
            textAlign: "center",
            fontSize: { xs: 30, md: "48px" },
          }}
        >
          Chcesz wiedzieć więcej? Napisz do nas!
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-around",
            mb: 10,
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Box sx={{ mt: { xs: 4, md: "0" } }}>
            <Typography variant="h6">
              Masz dodatkowe pytania? A może potrzebujesz wsparcia?
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontFamily: "VisbyCF-Medium", fontSize: "16px", mb: 4 }}
            >
              Możesz na nas liczyć. Po prostu do nas napisz.
            </Typography>
            <Box sx={{ display: "flex", gap: 1, my: 2 }}>
              <Icon color="primary" icon={Icons.EmailIcon} />
              <Typography
                component="a"
                href="mailto:kontakt@h2home.pl"
                sx={{ textDecoration: "none" }}
              >
                kontakt@h2home.pl
              </Typography>
            </Box>
            <FormGroup />
          </Box>
          <img
            style={{ alignSelf: "center" }}
            height="400px"
            src={consultant}
            alt="happy consultant"
          />
        </Box>
      </GridWrapper>
    </Box>
  );
};
