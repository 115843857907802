import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
import { CheckboxType } from "./types";

export const Checkbox = ({
  label,
  required,
  handleChange,
  name,
}: CheckboxType) => {
  return (
    <FormControlLabel
      required={required}
      control={<MuiCheckbox name={name} onChange={handleChange} size="small" />}
      label={label}
    />
  );
};
