import { Box, Button, Typography } from "@mui/material";
import { colors } from "@root/theme/colors";

import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { isMobile, isSafari } from "react-device-detect";
import { default as MuiCarousel } from "react-material-ui-carousel";
import "./Post.css";
import { PostProps } from "./types";

const DifferentColorSpan = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant="h2"
    component="span"
    sx={{
      fontSize: "25px",
      color: colors.primaryMain,
      mb: 3,
      mixBlendMode: isSafari || isMobile ? "unset" : "difference",
    }}
  >
    {children}
  </Typography>
);

export const Post = ({
  title,
  differentColorTitle,
  firstContent,
  secondContent,
  buttonText,
  img,
  imgPosition,
}: PostProps) => {
  const [lightboxImage, setLightboxImage] = useState<string | undefined>(
    undefined
  );

  const isLeft = imgPosition === "left";

  return (
    <Box
      sx={{
        display: "flex",
        mt: 2,
        mb: 6,
        gap: "30px",
        flexDirection: { xs: "column", sm: isLeft ? "row" : "row-reverse" },
        "&:not(:first-of-type)": {
          borderTop: `1px solid ${colors.primaryLight}`,
          paddingTop: 6,
        },
      }}
    >
      <MuiCarousel
        autoPlay={false}
        animation="slide"
        className="post-image"
        indicators={false}
        swipe
        sx={{
          width: { xs: "100%", md: "50%" },
          height: "100%",
          padding: 0,
        }}
      >
        {img.map((image, index) => (
          <img
            style={{
              objectFit: "cover",
              aspectRatio: "11.5/9.2",
              objectPosition: "top",
              width: "100%",
              height: "100%",
            }}
            key={index}
            onClick={() => setLightboxImage(image)}
            src={image}
            alt={`${title}`}
          />
        ))}
      </MuiCarousel>
      <Box
        sx={{
          width: { xs: "100%", md: "60%" },
          m: { md: isLeft ? "0 0 0 60px" : "0 60px 0 0" },
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: "25px", color: colors.primaryDark, mb: 3 }}
        >
          {title}
          <DifferentColorSpan>{differentColorTitle}</DifferentColorSpan>
        </Typography>

        <Typography
          variant="body1"
          sx={{ mb: 3 }}
          className="post-first-content"
          dangerouslySetInnerHTML={{ __html: firstContent }}
        />

        <Typography
          variant="body1"
          sx={{ mb: 3 }}
          className="post-second-content"
          dangerouslySetInnerHTML={{ __html: secondContent }}
        />
        <Button
          color="secondary"
          variant="contained"
          href="/register"
          component="a"
        >
          {buttonText}
        </Button>
      </Box>
      <Lightbox
        image={lightboxImage}
        onClose={() => setLightboxImage(undefined)}
      />
    </Box>
  );
};
