import { Box, LinearProgress, Typography } from "@mui/material";
import { PasswordErrors } from "@root/components/Forms/CustomerRegisterForm/types";
import { Icon, Icons } from "@root/components/Icon";

type PasswordValidationProps = {
  passwordErrors: PasswordErrors;
};

export const PasswordValidation = ({
  passwordErrors,
}: PasswordValidationProps) => {
  const isMinLengthError = passwordErrors.minLengthError.length > 0;
  const isUppercaseAndLowercaseError =
    passwordErrors.uppercaseAndLowercaseError.length > 0;
  const isNumberError = passwordErrors.numberError.length > 0;

  const validationVariants = [
    {
      icon: isMinLengthError ? (
        <Icon
          color="error"
          data-cy="length-error"
          icon={Icons.CircleOutlinedIcon}
        />
      ) : (
        <Icon
          color="primary"
          data-cy="length-success"
          icon={Icons.CheckCircleIcon}
        />
      ),
      text: "Co najmniej 8 znaków",
      isValid: !isMinLengthError,
    },
    {
      icon: isUppercaseAndLowercaseError ? (
        <Icon
          color="error"
          data-cy="lower-upper-error"
          icon={Icons.CircleOutlinedIcon}
        />
      ) : (
        <Icon
          color="primary"
          data-cy="lower-upper-success"
          icon={Icons.CheckCircleIcon}
        />
      ),
      text: "Co najmniej jedną dużą i jedną małą literę",
      isValid: !isUppercaseAndLowercaseError,
    },
    {
      icon: isNumberError ? (
        <Icon
          color="error"
          data-cy="number-error"
          icon={Icons.CircleOutlinedIcon}
        />
      ) : (
        <Icon
          color="primary"
          data-cy="number-success"
          icon={Icons.CheckCircleIcon}
        />
      ),
      text: "Co najmniej jedną cyfrę",
      isValid: !isNumberError,
    },
  ];

  const passwordProgress = () =>
    (validationVariants.filter((variant) => variant.isValid).length /
      validationVariants.length) *
    100;

  const calculateColor = () => {
    if (passwordProgress() < 50) {
      return "error";
    }
    if (passwordProgress() < 100) {
      return "success";
    }
    return "primary";
  };

  return (
    <Box>
      <Typography variant="body2">Hasło musi zawierać:</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {validationVariants.map((variant, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              opacity: variant.isValid ? 1 : 0.5,
            }}
          >
            {variant.icon}
            <Typography variant="body2">{variant.text}</Typography>
          </Box>
        ))}
      </Box>
      <Typography variant="body2" sx={{ my: 2 }}>
        Moc hasła
      </Typography>
      <LinearProgress
        color={calculateColor()}
        variant="determinate"
        value={passwordProgress()}
      />
    </Box>
  );
};
