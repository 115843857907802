import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { default as H2HLogo } from "@root/assets/h2hLogo.png";
import { Icon, Icons } from "@root/components/Icon";
import { colors } from "@root/theme/colors";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { GridWrapper } from "../../GridWrapper";
import "./Navbar.css";

const drawerWidth = 240;
const navItemsLeft = [
  { functions: "Funkcje" },
  { faq: "FAQ" },
  { pricing: "Cennik" },
  { contact: "Kontakt" },
];
const navItemsRight = isMobile
  ? [{ register: "Zarejestruj się" }]
  : [{ login: "Zaloguj się" }, { register: "Zarejestruj się" }];

const isInViewport = (element: any) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  const viewportPadding =
    (window.innerHeight || document.documentElement.clientHeight) / 3;
  return (
    rect.bottom >= viewportPadding &&
    rect.right >= viewportPadding &&
    rect.top + viewportPadding <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left + viewportPadding <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const Navbar = () => {
  const location = useLocation();
  const [offset, setOffset] = useState(0);
  const [inViewportState, setInViewportState] = useState<any>(
    Object.fromEntries(
      navItemsLeft
        .map((x) => Object.entries(x)[0][0])
        .map((x) => [x, isInViewport(document.getElementById(x))])
    )
  );

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        component="a"
        href="/#"
        sx={{
          flexGrow: 1,
          py: 1,
          textAlign: "center",
        }}
      >
        <img src={H2HLogo} width={150} />
      </Box>
      <Divider />
      <List>
        <Box>
          {navItemsLeft.map((item, index) => (
            <ListItem
              component="a"
              key={index}
              href={`/#${Object.keys(item)[0]}`}
              disablePadding
            >
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    component="span"
                    color={
                      location.hash === "#" + item ? "primary" : "secondary"
                    }
                  >
                    {Object.values(item)[0]}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </Box>
        <Box>
          {navItemsRight.map((item, index) => {
            return (
              <ListItem
                component="a"
                href={Object.keys(item)[0]}
                key={index}
                disablePadding
              >
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText primary={Object.values(item)[0]} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </Box>
      </List>
    </Box>
  );
  useEffect(() => {
    setInViewportState(
      Object.fromEntries(
        navItemsLeft
          .map((x) => Object.entries(x)[0][0])
          .map((x) => [x, isInViewport(document.getElementById(x))])
      )
    );
  }, [offset]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" sx={{ backgroundColor: colors.white }}>
        <GridWrapper>
          <Toolbar sx={{ px: "0 !important" }}>
            <Box
              component="a"
              href="/#"
              sx={{
                flexGrow: 1,
                textAlign: "initial",
                display: { xs: "block", sm: "block" },
              }}
            >
              <img src={H2HLogo} width={150} />
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
                minWidth: "75%",
              }}
            >
              <List
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  textTransform: "uppercase",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  {navItemsLeft.map((item) => (
                    <a
                      className={
                        "regular-link" +
                        (inViewportState[Object.keys(item)[0]]
                          ? " active-link"
                          : "")
                      }
                    >
                      <ListItemButton
                        key={Object.values(item)[0]}
                        onClick={() => {
                          document
                            .getElementById(Object.keys(item)[0])
                            ?.scrollIntoView({ behavior: "smooth" });
                        }}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {Object.values(item)[0]}
                      </ListItemButton>
                    </a>
                  ))}
                </Box>
                <Box sx={{ display: "flex" }}>
                  {navItemsRight.map((item, index) => {
                    const isLast = index === navItemsRight.length - 1;

                    return (
                      <ListItem
                        key={Object.values(item)[0]}
                        component="a"
                        href={Object.keys(item)[0]}
                        disablePadding
                        sx={{
                          width: "fit-content",
                        }}
                      >
                        <ListItemButton
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <ListItemText
                            primary={Object.values(item)[0]}
                            sx={
                              isLast
                                ? {
                                    border: `2px solid ${colors.secondaryLight}`,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                  }
                                : { padding: "5px 10px" }
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </Box>
              </List>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <Icon icon={Icons.MenuIcon} />
            </IconButton>
          </Toolbar>
        </GridWrapper>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};
