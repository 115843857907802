import { Box, Button, Typography } from "@mui/material";
import { AccordionSection } from "@root/components/Sections/AccordionSection";
import { Banner } from "@root/components/Sections/Banner";
import { Footer } from "@root/components/Sections/Footer";
import { FormSection } from "@root/components/Sections/FormSection";
import { Hero } from "@root/components/Sections/Hero";
import { ImagePostsGrid } from "@root/components/Sections/ImagePostsGrid/ImagePostsGrid";
import { Navbar } from "@root/components/Sections/Navbar";
import { Posts } from "@root/components/Sections/Posts";
import { PricingSection } from "@root/components/Sections/PricingSection";
import "./App.css";

function App() {
  return (
    <Box sx={{ zoom: { xl: 1.25 } }}>
      <Navbar />
      <Hero />
      <Posts />
      <Banner
        headerText="Sprawdź, co zyskujesz korzystając z H2Home"
        subheaderComponent={
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "16px", md: "20px" } }}
          >
            Ty projektujesz, my zajmujemy się całą resztą – i to zupełnie{" "}
            <Typography
              component="span"
              sx={{ fontFamily: "VisbyCF-ExtraBold", fontSize: "inherit" }}
            >
              za darmo!
            </Typography>
          </Typography>
        }
      />
      <ImagePostsGrid />
      <AccordionSection />
      <PricingSection />
      <Banner
        idForAnchor="ContactUs"
        headerText="Zaczynamy przygodę z H2H?"
        subheaderComponent={
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                mt: 6,
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Button href="/register" color="secondary" variant="contained">
                Zarejestruj się
              </Button>
            </Box>
          </Box>
        }
      />
      <FormSection />
      <Footer />
    </Box>
  );
}

export default App;
