import { Box, Button, Typography } from "@mui/material";
import { client } from "@root/utils.ts";
import { useState } from "react";
import { TextField } from "@root/components/TextField";

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const FormGroup = () => {
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const onSubmit = async () => {
    setIsSending(true);
    setIsSent(false);
    try {
      const resp = await client.put(
        `/users-module/waitinglist/query`,
        JSON.stringify({
          email,
          content,
        })
      );
      if (resp.status === 204) {
        setIsSent(true);
          // @ts-ignore
          gtag('event', 'waiting_list_query', {
          });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSending(false);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        mt: 6,
      }}
    >
      {!isSent && (
        <>
          <TextField
            name="email"
            placeholder="Twój email"
            value={email}
            disabled={isSending}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            rows={6}
            multiline
            placeholder="Napisz wiadomość"
            value={content}
            disabled={isSending}
            onChange={(e) => setContent(e.target.value)}
          />
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            disabled={isSending || !content || !email || !validateEmail(email)}
            onClick={onSubmit}
          >
            WYŚlIJ
          </Button>
        </>
      )}
      {isSent && (
        <Typography
          variant="body1"
          sx={{ fontSize: { xs: "16px", md: "20px" } }}
        >
          Zapytanie zostało wysłane.{" "}
        </Typography>
      )}
    </Box>
  );
};
