import { Box, Typography } from "@mui/material";
import { default as LogoOpacityDark } from "@root/assets/h2hLogo-opacity-dark.png";
import { default as LogoOpacity } from "@root/assets/h2hLogo-opacity.png";
import { default as LogoYellow } from "@root/assets/h2hLogo-transparent-yellow.png";
import { default as Logo } from "@root/assets/h2hLogo-transparent.png";
import { default as HeroImage } from "@root/assets/hero_image.png";
import { Tab } from "@root/components/Sections/Login/types";
import { colors } from "@root/theme/colors";

type LeftPartProps = {
  tab?: Tab;
};

const BackgroundImageRepeat = ({ isClient }: { isClient: boolean }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        left: 30,
        top: 0,
        width: "50px",
        height: "100%",
        backgroundImage: isClient
          ? `url(${LogoOpacity})`
          : `url(${LogoOpacityDark})`,
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
      }}
    />
  );
};

const ColorSpan = ({
  children,
  isClient,
}: {
  children: React.ReactNode;
  isClient: boolean;
}) => (
  <Typography
    variant="body1"
    component="span"
    sx={{ color: isClient ? colors.primaryDark : colors.white }}
  >
    {children}
  </Typography>
);

export const LeftPart = ({ tab }: LeftPartProps) => {
  const isClient = tab === "client";

  return (
    <Box
      sx={{
        display: { xs: "none", md: "block" },
        backgroundColor: isClient ? colors.primaryMain : colors.secondaryDark,
        transition: "background 0.3s ease",
        height: "100vh",
        width: "30%",
        pl: 13,
        pt: 3,
      }}
    >
      <BackgroundImageRepeat isClient={isClient} />
      <Box>
        <a href="/">
          <img height={40} src={isClient ? Logo : LogoYellow} />
        </a>
        <Typography
          variant="body1"
          sx={{ mt: 3, color: isClient ? colors.black : colors.primaryMain }}
        >
          Projektuj <ColorSpan isClient={isClient}>z pasją</ColorSpan>
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: isClient ? colors.black : colors.primaryMain, mb: 3 }}
        >
          zarządzaj <ColorSpan isClient={isClient}>z łatwością!</ColorSpan>
        </Typography>
        <img width="70%" src={HeroImage} />
      </Box>
    </Box>
  );
};
