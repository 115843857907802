import { Box } from "@mui/material";
import { LeftPart } from "@root/components/LeftPart";
import { RightPart } from "@root/components/RightPart";
import { Tab } from "@root/components/Sections/Login/types";
import { useState } from "react";
import { ResetPasswordForm } from "../../Forms/ResetPasswordForm";
export const ResetPassword = () => {
  const [tab, setTab] = useState<Tab>("client");
  const isClient = tab === "client";

  return (
    <Box sx={{ display: "flex" }}>
      <LeftPart tab={tab} />
      <RightPart tab={tab} setTab={setTab} isTabs={false}>
        <ResetPasswordForm isClient={isClient} />
      </RightPart>
    </Box>
  );
};
