import { Grid } from "@mui/material";

type GridWrapperProps = {
  children: React.ReactNode;
};

export const GridWrapper = ({ children }: GridWrapperProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={1} xl={2} />
      <Grid item xs={10} xl={8}>
        {children}
      </Grid>
      <Grid item xs={1} xl={2} />
    </Grid>
  );
};
