import { Box, Button, Divider } from "@mui/material";
import { colors } from "@root/theme/colors";
import { AnimatePresence, motion } from "framer-motion";
import { Icon, Icons } from "../Icon";
import { Tab } from "../Sections/Login/types";

type RightPartProps = {
  tab?: Tab;
  setTab?: (tab: Tab) => void;
  children: React.ReactNode;
  isTabs?: boolean;
};

type SwitchButtonProps = Omit<RightPartProps, "children"> & {
  tabToSwitch: Tab;
};

const getTabName = (tab: Tab) => {
  return tab === "architect" ? "Architekt" : "Klient";
};

const SwitchButton = ({ tabToSwitch, tab, setTab }: SwitchButtonProps) => {
  return (
    <Button
      data-cy={`${getTabName(tabToSwitch)?.toLowerCase()}-switch-button`}
      onClick={() => setTab && setTab(tabToSwitch)}
      variant="text"
      sx={{
        display: "flex",
        px: 4,
        py: 2,
        gap: 1,
        flexDirection: "column",
        color: tab === tabToSwitch ? colors.primaryMain : colors.black40,
        "-webkit-text-stroke":
          tab === tabToSwitch ? `1px ${colors.primaryMain}` : "none",
      }}
    >
      <Icon
        icon={
          tabToSwitch === "architect"
            ? Icons.HomeWorkRoundedIcon
            : Icons.PersonIcon
        }
      />
      {getTabName(tabToSwitch)}
    </Button>
  );
};

export const RightPart = ({
  tab,
  setTab,
  children,
  isTabs = true,
}: RightPartProps) => {
  const isTabSet = tab !== undefined;

  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        height: "100vh",
        width: { xs: "100%", md: "70%" },
        pt: 3,
        pl: 3,
      }}
    >
      {isTabs && (
        <Box
          sx={{
            ...{
              display: "flex",
              gap: 1,
              width: "100%",
              transition: "all 0.5s",
              justifyContent: "center",
              alignItems: "center",
            },
            ...(isTabSet
              ? {
                  height: "auto",
                }
              : {
                  height: "100%",
                }),
          }}
        >
          <Box
            sx={
              isTabSet
                ? {
                    width: "100%",
                    display: "flex",
                    gap: 1,
                    justifyContent: "center",
                  }
                : {
                    display: "flex",
                    gap: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }
            }
          >
            <SwitchButton tabToSwitch="architect" tab={tab} setTab={setTab} />
            <Divider orientation="vertical" flexItem />
            <SwitchButton tabToSwitch="client" tab={tab} setTab={setTab} />
          </Box>
        </Box>
      )}
      <AnimatePresence>
        {isTabSet && (
          <motion.div
            transition={{ duration: 0.7 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box
              sx={{
                height: "90%",
                width: { xs: "70%", md: "40%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                transform: "scale(0.95)",
              }}
            >
              {children}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};
