import { Box, Button, Typography } from "@mui/material";
import { SVGComponent as BackgroundBlob } from "@root/assets/blob";
import { default as HeroImage } from "@root/assets/hero_image2.png";
import { GridWrapper } from "@root/components/GridWrapper";
import { colors } from "@root/theme/colors";
import "./Hero.css";

const ColorSpan = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="h1" component="span" sx={{ color: colors.primaryDark }}>
    {children}
  </Typography>
);
const BoldSpan = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant="h1"
    component="span"
    sx={{ fontFamily: "VisbyCF-ExtraBold", fontSize: "inherit" }}
  >
    {children}
  </Typography>
);

export const Hero = () => {
  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: -1,
          width: "100%",
          height: { xs: "900px", md: "400px" },
          backgroundColor: "#DDE13F",
          left: 0,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: { xs: "880px", md: "300px" },
          zIndex: -1,
          width: "100%",
        }}
      >
        <BackgroundBlob style={{}} />
      </Box>

      <GridWrapper>
        <Box
          sx={{
            mt: { xs: 10, md: 20 },
            mb: 20,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "50%" }, mr: { xs: "40px" } }}>
            <Typography
              variant="h1"
              sx={{ lineHeight: "75px", fontSize: { xs: "40px", md: "48px" } }}
            >
              Projektuj <ColorSpan>z pasją,</ColorSpan> <br /> zarządzaj{" "}
              <ColorSpan>z łatwością!</ColorSpan>
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontFamily: "VisbyCF-Medium", mt: 4 }}
            >
              Odkryj <BoldSpan>DARMOWE narzędzie</BoldSpan> do tworzenia list
              zakupowych <br />i prowadzenia projektów{" "}
              <BoldSpan>dla architektów oraz projektantów wnętrz.</BoldSpan>
            </Typography>
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 0, mt: 6 }}>
              <Button color="secondary" variant="contained" href="/register">
                Zarejestruj się
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
              width: { xs: "100%", md: "50%" },
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
                mt: 6,
              }}
            >
              <Button
                size="small"
                color="secondary"
                variant="contained"
                href="/register"
              >
                Zarejestruj się
              </Button>
            </Box>
            <Box
              sx={{
                width: "80%",
                margin: "0 auto",
                mt: { xs: "60px", md: "30px" },
                textAlign: "center",
                transform: {
                  xs: "scale(1.2)",
                  md: "scale(1.4)",
                  xl: "scale(1)",
                },
              }}
            >
              <img width="100%" src={HeroImage} />
            </Box>
          </Box>
        </Box>
      </GridWrapper>
    </Box>
  );
};
