import { ThemeProvider } from "@mui/material/styles";
import { CookieConsent } from "@root/components/CookieConsent";
import { EmailConfirmation } from "@root/components/Sections/EmailConfirmation/EmailConfirmation.tsx";
import { ErrorSection } from "@root/components/Sections/ErrorSection";
import { ForgotPassword } from "@root/components/Sections/ForgotPassword/ForgotPassword.tsx";
import { Login } from "@root/components/Sections/Login";
import { Register } from "@root/components/Sections/Register/Register.tsx";
import { ResetPassword } from "@root/components/Sections/ResetPassword";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import "./index.css";
import { theme } from "./theme/theme";

Sentry.init({
  dsn: "https://f4bf237ade0b173bc11583b36eee9686@o4506053436833792.ingest.sentry.io/4506053438210048",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/api\.app\.h2home\.pl/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorSection />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorSection />,
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <ErrorSection />,
  },
  {
    path: "/register-client",
    element: <Register />,
    errorElement: <ErrorSection />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <ErrorSection />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    errorElement: <ErrorSection />,
  },
  {
    path: "/invalid-email-confirmation-url",
    element: <EmailConfirmation />,
    errorElement: <ErrorSection />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CookieConsent />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
