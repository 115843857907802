import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { errorMessages } from "@root/components/Forms/errorMessages";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { FormValues, FormValuesErrors, ResetPasswordFormProps } from "./types";
import {client} from "@root/utils.ts";
import { TextField } from "@root/components/TextField";

export const ResetPasswordForm = ({ isClient }: ResetPasswordFormProps) => {
  const [submitError, setSubmitError] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [formValues, setFormValues] = useState<FormValues>({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<FormValuesErrors>({
    password: "",
    confirmPassword: "",
  });

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(errorMessages.password.requiredError),
    oldPassword: yup
        .string()
        .required(errorMessages.password.requiredError),
    confirmPassword: yup.string()
      .oneOf([yup.ref("password"), undefined], errorMessages.password.passwordsMatchError)
      .required(errorMessages.name.requiredError),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.checked,
      }));
      return;
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    if (!params.has('token')) {
        window.location.href = "/forgot-password?invalidToken=true";
    } else{
      setToken(params.get('token') as string);
    }
    
  }, [location.pathname, location.search]);

  const validate = () => {
    schema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setErrors((prevState) => ({
          ...prevState,
          oldPassword: "",
          password: "",
          confirmPassword: "",
        }));
      })
      .catch((err) => {
        const newErrors = err.inner.reduce(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (acc: any, curr: any) => {
            if (curr.path === "password") {
              return {
                ...acc,
                password: {
                  ...acc.password,
                  [curr.type === "min"
                    ? "minLengthError"
                    : curr.type === "matches" &&
                      curr.params.regex.source.includes("a-z")
                    ? "uppercaseAndLowercaseError"
                    : "numberError"]: curr.message,
                },
              };
            } else {
              return {
                ...acc,
                [curr.path]: curr.message,
              };
            }
          },
          {
            oldPassword: "",
            password: "",
            confirmPassword: "",
          }
        );
        setErrors(newErrors);
      });
  };
  const handleSubmit = async () => {
    validate();
    const resp = await client.post(
        `/Identity/Account/ResetPasswordExt`, JSON.stringify({...formValues, token})
    );
    
    if (resp.status === 204) {
      window.location.href = "/login?passwordReset=true";
    } else {
      if (resp.data){
        setSubmitError(resp.data);
      } else{
        setSubmitError("Wystąpił błąd podczas resetu hasła");
      }
    }
  };

  useEffect(() => {
    setFormValues({
      password: "",
      confirmPassword: "",
    });
    setErrors({
      password: "",
      confirmPassword: "",
    });
  }, [isClient]);

  useEffect(() => {
    if (
      !formValues.password &&
      !formValues.confirmPassword
    ) {
      return;
    }
    validate();
  }, [formValues]);

  return (
    <Box sx={{ width: "100%", marginTop: "60%" }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">Zresetuj hasło</Typography>
        {submitError && (
            <Typography sx={{ color: "red" }}>{submitError}</Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 3,
          width: "100%",
        }}
      >
        <TextField
          type="password"
          value={formValues.password}
          onChange={handleChange}
          name="password"
          placeholder="Zdefiniuj nowe hasło"
          label="Hasło*"
          variant="outlined"
          sx={{ width: "100%" }}
          error={
            !!errors.password
          }
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            autocomplete: "new-password",
          }}
        />
        <TextField
            type="password"
            value={formValues.confirmPassword}
            onChange={handleChange}
            name="confirmPassword"
            placeholder="Potwierdź swoje hasło"
            label="Potwierdź hasło*"
            variant="outlined"
            sx={{ width: "100%" }}
            error={
              !!errors.confirmPassword
            }
            InputLabelProps={{
              shrink: true,
            }}
        />
        <Button
          onClick={handleSubmit}
          disabled={
            !formValues.password ||
            !formValues.confirmPassword
          }
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            color: "white",
            fontFamily: "VisbyCF-ExtraBold",
          }}
        >
          Zresetuj hasło
        </Button>
      </Box>
    </Box>
  );
};
