import { Box, Button, FormHelperText, Link, Typography } from "@mui/material";
import { Checkbox } from "@root/components/Forms/Checkbox";
import { PasswordValidation } from "@root/components/Forms/PasswordValidation";
import { errorMessages } from "@root/components/Forms/errorMessages";
import { TextField } from "@root/components/TextField";
import { client } from "@root/utils.ts";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { FormValues, FormValuesErrors, RegisterFormProps } from "./types";

export const CustomerRegisterForm = ({ isClient }: RegisterFormProps) => {
  const location = useLocation();
  const [submitError, setSubmitError] = useState<string>("");
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    name: "",
    surname: "",
    password: "",
    confirmPassword: "",
    policyAccepted: false,
    termsAccepted: false,
  });
  const [errors, setErrors] = useState<FormValuesErrors>({
    email: "",
    name: "",
    surname: "",
    password: {
      minLengthError: errorMessages.password.minLengthError,
      uppercaseAndLowercaseError:
        errorMessages.password.uppercaseAndLowercaseError,
      numberError: errorMessages.password.numberError,
    },
    confirmPassword: "",
    policyAccepted: "",
    termsAccepted: "",
  });

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(errorMessages.email.patternError)
      .required(errorMessages.email.requiredError)
      .max(50, errorMessages.taxId.maxLengthError),
    name: yup
      .string()
      .required(errorMessages.name.requiredError)
      .max(50, errorMessages.taxId.maxLengthError),
    surname: yup
      .string()
      .required(errorMessages.surname.requiredError)
      .max(50, errorMessages.taxId.maxLengthError),
    password: yup
      .string()
      .required(errorMessages.password.requiredError)
      .min(8, errorMessages.password.minLengthError)
      .max(50, errorMessages.taxId.maxLengthError)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        errorMessages.password.uppercaseAndLowercaseError
      )
      .matches(/\d/, errorMessages.password.numberError),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), undefined],
        errorMessages.password.passwordsMatchError
      )
      .required(errorMessages.name.requiredError),
    policyAccepted: yup
      .boolean()
      .oneOf([true], errorMessages.policyAccepted.requiredError),
    termsAccepted: yup
      .boolean()
      .oneOf([true], errorMessages.termsAccepted.requiredError),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.checked,
      }));
      return;
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const validate = () => {
    schema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setErrors((prevState) => ({
          ...prevState,
          password: {
            minLengthError: "",
            uppercaseAndLowercaseError: "",
            numberError: "",
          },
          email: "",
          confirmPassword: "",
          name: "",
          surname: "",
          policyAccepted: "",
          termsAccepted: "",
        }));
      })
      .catch((err) => {
        const newErrors = err.inner.reduce(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (acc: any, curr: any) => {
            if (curr.path === "password") {
              return {
                ...acc,
                password: {
                  ...acc.password,
                  [curr.type === "min"
                    ? "minLengthError"
                    : curr.type === "matches" &&
                      curr.params.regex.source.includes("a-z")
                    ? "uppercaseAndLowercaseError"
                    : "numberError"]: curr.message,
                },
              };
            } else {
              return {
                ...acc,
                [curr.path]: curr.message,
              };
            }
          },
          {
            email: "",
            confirmPassword: "",
            password: {
              minLengthError: "",
              uppercaseAndLowercaseError: "",
              numberError: "",
            },
            name: "",
            surname: "",
            policyAccepted: "",
            termsAccepted: "",
          }
        );
        setErrors(newErrors);
      });
  };
  const handleSubmit = async () => {
    validate();
    const resp = await client.post(
      `/Identity/Account/SignUpExt`,
      JSON.stringify({ ...formValues, registrationType: "Customer" })
    );

    if (resp.status === 204) {
      window.location.href = "/login?registered=true";
    } else {
      if (resp.data) {
        setSubmitError(resp.data);
      } else {
        setSubmitError("Wystąpił błąd podczas rejestracji");
      }
    }
  };

  useEffect(() => {
    setFormValues({
      email: "",
      name: "",
      surname: "",
      password: "",
      confirmPassword: "",
      policyAccepted: false,
      termsAccepted: false,
    });
    setErrors({
      email: "",
      name: "",
      surname: "",
      confirmPassword: "",
      password: {
        minLengthError: errorMessages.password.minLengthError,
        uppercaseAndLowercaseError:
          errorMessages.password.uppercaseAndLowercaseError,
        numberError: errorMessages.password.numberError,
      },
      policyAccepted: "",
      termsAccepted: "",
    });
  }, [isClient]);

  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    if (params.has("customerEmail")) {
      const email = params.get("customerEmail")!;
      setFormValues((prevState) => ({
        ...prevState,
        email,
      }));
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (
      !formValues.email &&
      !formValues.password &&
      !formValues.confirmPassword &&
      !formValues.name &&
      !formValues.surname &&
      !formValues.policyAccepted &&
      !formValues.termsAccepted
    ) {
      return;
    }
    validate();
  }, [formValues]);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">Utwórz konto klienta</Typography>
        {submitError && (
          <Typography sx={{ color: "red" }}>{submitError}</Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          mt: 3,
          width: "100%",
        }}
      >
        <Box>
          <TextField
            size="small"
            type="email"
            value={formValues.email}
            onChange={handleChange}
            name="email"
            placeholder="Wpisz swój adres e-mail"
            label="Adres e-mail*"
            variant="outlined"
            sx={{ width: "100%" }}
            error={!!errors.email}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              autocomplete: "off",
              "data-cy": "email-input",
            }}
          />
          {errors.email && (
            <FormHelperText data-cy="email-error">
              {errors.email}
            </FormHelperText>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ width: "100%" }}>
            <TextField
              size="small"
              type="text"
              value={formValues.name}
              onChange={handleChange}
              name="name"
              placeholder="Wpisz swoje imię"
              label="Imię*"
              variant="outlined"
              sx={{ width: "100%" }}
              error={!!errors.name}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autocomplete: "off",
                "data-cy": "name-input",
              }}
            />
            {errors.name && (
              <FormHelperText data-cy="name-error">
                {errors.name}
              </FormHelperText>
            )}
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              size="small"
              type="text"
              value={formValues.surname}
              onChange={handleChange}
              name="surname"
              placeholder="Wpisz swoje nazwisko"
              label="Nazwisko*"
              variant="outlined"
              sx={{ width: "100%" }}
              error={!!errors.surname}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autocomplete: "off",
                "data-cy": "surname-input",
              }}
            />
            {errors.surname && (
              <FormHelperText data-cy="surname-error">
                {errors.surname}
              </FormHelperText>
            )}
          </Box>
        </Box>
        <TextField
          size="small"
          type="password"
          value={formValues.password}
          onChange={handleChange}
          name="password"
          placeholder="Zdefiniuj swoje hasło"
          label="Hasło*"
          variant="outlined"
          sx={{ width: "100%" }}
          error={
            !!errors.password.minLengthError ||
            !!errors.password.uppercaseAndLowercaseError ||
            !!errors.password.numberError
          }
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            autocomplete: "new-password",
            "data-cy": "password-input",
          }}
        />
        <TextField
          size="small"
          type="password"
          value={formValues.confirmPassword}
          onChange={handleChange}
          name="confirmPassword"
          placeholder="Potwierdź swoje hasło"
          label="Potwierdź hasło*"
          variant="outlined"
          sx={{ width: "100%" }}
          error={!!errors.confirmPassword}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            autocomplete: "new-password",
            "data-cy": "confirm-password-input",
          }}
        />
        <PasswordValidation passwordErrors={errors.password} />

        <Box>
          <Checkbox
            handleChange={handleChange}
            required
            name="termsAccepted"
            label={
              <Typography variant="body2">
                Akceptuję{" "}
                <Link
                  target="_blank"
                  href="/assets/regulamin.pdf"
                  sx={{ textAlign: "center" }}
                  color="secondary"
                >
                  regulamin
                </Link>
              </Typography>
            }
          />
          <Checkbox
            handleChange={handleChange}
            required
            name="policyAccepted"
            label={
              <Typography variant="body2">
                Akceptuję{" "}
                <Link
                  target="_blank"
                  href="/assets/polityka.pdf"
                  sx={{ textAlign: "center" }}
                  color="secondary"
                >
                  politykę prywatności
                </Link>
              </Typography>
            }
          />
        </Box>

        <Button
          onClick={handleSubmit}
          data-cy="register-button"
          disabled={
            !!errors.email ||
            !!errors.confirmPassword ||
            !!errors.password.minLengthError ||
            !!errors.password.uppercaseAndLowercaseError ||
            !!errors.password.numberError ||
            !formValues.email ||
            !formValues.name ||
            !formValues.surname ||
            !formValues.password ||
            !formValues.confirmPassword ||
            !formValues.policyAccepted ||
            !formValues.termsAccepted
          }
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            color: "white",
            fontFamily: "VisbyCF-ExtraBold",
          }}
        >
          Utwórz konto
        </Button>
        <Link data-cy="login-link" href="/login" sx={{ textAlign: "center" }}>
          <Typography variant="body2">Masz już konto? Zaloguj się</Typography>
        </Link>
      </Box>
    </Box>
  );
};
