export const errorMessages = {
  name: {
    requiredError: "Imię jest wymagane",
    maxLengthError: "Przekroczono dopuszczalną liczbę znaków",
  },
  surname: {
    requiredError: "Nazwisko jest wymagane",
    maxLengthError: "Przekroczono dopuszczalną liczbę znaków",
  },
  email: {
    requiredError: "Email jest wymagany",
    patternError: "Adres e-mail jest niepoprawny",
    maxLengthError: "Przekroczono dopuszczalną liczbę znaków",
  },
  phoneNumber: {
    maxLengthError: "Przekroczono dopuszczalną liczbę znaków",
  },
  taxId: {
    maxLengthError: "Przekroczono dopuszczalną liczbę znaków",
  },
  companyName: {
    requiredError: "Nazwa firmy jest wymagana",
    maxLengthError: "Przekroczono dopuszczalną liczbę znaków",
  },
  password: {
    requiredError: "Hasło jest wymagane",
    minLengthError: "Hasło musi zawierać co najmniej 8 znaków",
    maxLengthError: "Przekroczono dopuszczalną liczbę znaków",
    uppercaseAndLowercaseError:
      "Hasło musi zawierać co najmniej jedną dużą i jedną małą literę",
    numberError: "Hasło musi zawierać co najmniej jedną cyfrę",
    passwordsMatchError: "Hasła nie są takie same",
  },
  policyAccepted: {
    requiredError: "Zaakceptowanie regulaminu jest wymagane",
  },
  termsAccepted: {
    requiredError: "Zaakceptowanie polityki prywatności jest wymagane",
  },
};
