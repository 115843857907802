import { Box } from "@mui/material";
import { EmailConfirmationForm } from "@root/components/Forms/EmailConfirmationForm";
import { LeftPart } from "@root/components/LeftPart";
import { RightPart } from "@root/components/RightPart";
import {useState} from "react";
import {Tab} from "@root/components/Sections/Login/types.ts";

export const EmailConfirmation = () => {
    const [tab, setTab] = useState<Tab>("client");
    return (
        <Box sx={{ display: "flex" }}>
            <LeftPart tab={tab}/>
            <RightPart isTabs={false} tab={tab} setTab={setTab}>
        <EmailConfirmationForm />
      </RightPart>
    </Box>
  );
};
