import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuIcon from "@mui/icons-material/Menu";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PersonIcon from "@mui/icons-material/Person";
import TwitterIcon from "@mui/icons-material/Twitter";
import { SvgIconProps as MuiIconProps } from "@mui/material";

// eslint-disable-next-line react-refresh/only-export-components
export enum Icons {
  MenuIcon = "menuIcon",
  AddBoxIcon = "addBoxIcon",
  EmailIcon = "emailIcon",
  FacebookIcon = "facebookIcon",
  InstagramIcon = "instagramIcon",
  LinkedInIcon = "linkedInIcon",
  TwitterIcon = "twitterIcon",
  CircleOutlinedIcon = "circleOutlinedIcon",
  CheckCircleIcon = "checkCircleIcon",
  InfoIcon = "infoIcon",
  InfoOutlinedIcon = "infoOutlinedIcon",
  HomeWorkRoundedIcon = "homeWorkRoundedIcon",
  PersonIcon = "personIcon",
  CheckIcon = "checkIcon",
  NewReleasesIcon = "newReleasesIcon",
}

export interface IconProps extends MuiIconProps {
  icon: Icons;
}

export const Icon = ({ icon, ...rest }: IconProps) => {
  const icons = {
    [Icons.MenuIcon]: MenuIcon,
    [Icons.AddBoxIcon]: AddBoxIcon,
    [Icons.EmailIcon]: EmailIcon,
    [Icons.FacebookIcon]: FacebookIcon,
    [Icons.InstagramIcon]: InstagramIcon,
    [Icons.LinkedInIcon]: LinkedInIcon,
    [Icons.TwitterIcon]: TwitterIcon,
    [Icons.CircleOutlinedIcon]: CircleOutlinedIcon,
    [Icons.CheckCircleIcon]: CheckCircleIcon,
    [Icons.InfoIcon]: InfoIcon,
    [Icons.InfoOutlinedIcon]: InfoOutlinedIcon,
    [Icons.HomeWorkRoundedIcon]: HomeWorkRoundedIcon,
    [Icons.PersonIcon]: PersonIcon,
    [Icons.CheckIcon]: CheckIcon,
    [Icons.NewReleasesIcon]: NewReleasesIcon,
  };
  const Component = icons[icon];
  return <Component {...rest} />;
};
