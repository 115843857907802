export interface Colors {
  primaryMain: string;
  primaryDark: string;
  primaryLight: string;
  secondaryMain: string;
  secondaryDark: string;
  secondaryLight: string;
  white: string;
  black: string;
  black40: string;
  black90: string;
  gray: string;
  warning: string;
  success: string;
  lightGray: string;
}

export const colors: Colors = {
  primaryMain: "#D1D700",
  primaryDark: "#657728",
  primaryLight: "#DDE13F",
  secondaryMain: "#284D6B",
  secondaryDark: "#102F49",
  secondaryLight: "#527CA4",
  white: "#FFFFFF",
  black: "#000000",
  black40: "#00000099",
  black90: "#00000014",
  gray: "#A1AEB7",
  warning: "#ED6C02",
  success: "#2E7D32",
  lightGray: "#F8F8F8",
};
