import {
  Box,
  Button,
  FormHelperText,
  Link,
  // Link,
  Typography,
} from "@mui/material";
import { errorMessages } from "@root/components/Forms/errorMessages";
import { TextField } from "@root/components/TextField";
import { colors } from "@root/theme/colors";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { client, env_urls } from "@root/utils.ts";
import * as yup from "yup";

type LoginFormProps = {
  isClient: boolean;
};

type FormValues = {
  email: string;
  password: string;
  returnUrl?: string;
};

type Errors = {
  [key: string]: string;
};

export const LoginForm = ({ isClient }: LoginFormProps) => {
  const location = useLocation();
  const [isEmailSending, setIsEmailSending] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");
  const [emailNotConfirmed, setEmailNotConfirmed] = useState<boolean>(false);
  const [submitInfo, setSubmitInfo] = useState<string>("");
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<FormValues>({
    email: "",
    password: "",
  });

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(errorMessages.email.patternError)
      .required(errorMessages.email.requiredError),
    password: yup.string().required(errorMessages.password.requiredError),
  });

  const validate = () => {
    if (!isValidating) {
      setIsValidating(true);
      schema
        .validate(formValues, { abortEarly: false })
        .then(() => {
          setErrors((prevState) => ({
            ...prevState,
            email: "",
            password: "",
          }));
        })
        .catch((err) => {
          const newErrors = err.inner.reduce(
            (acc: Errors, curr: Errors) => ({
              ...acc,
              [curr.path]: curr.message,
            }),
            {}
          );
          setErrors(newErrors);
        })
        .finally(() => setIsValidating(false));
    }
  };

  const handleResendConfirmation = async () => {
    setIsEmailSending(true);
    const params = new URLSearchParams();
    params.set("email", formValues.email);
    const resp = await client.post(
      `/Identity/Account/EmailConfirmation?${params.toString()}`
    );

    if (resp.status === 204) {
      setSubmitError("");
      setSubmitInfo("Na podany adres email został wysłany link aktywacyjny.");
      setEmailNotConfirmed(false);
    } else {
      if (resp.data) {
        setSubmitError(resp.data);
      } else {
        setSubmitError("Wystąpił błąd podczas generowana linku aktywacyjnego");
      }
    }

    setIsEmailSending(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    setFormValues({
      email: "",
      password: "",
    });
    setErrors({
      email: "",
      password: "",
    });
  }, [isClient]);

  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    if (params.has("registered")) {
      setSubmitInfo(
        "Konto zostało utworzone. Sprawdź swoją skrzynkę pocztową i aktywuj konto."
      );
    }

    if (params.has("emailConfirmed")) {
      setSubmitInfo("Konto zostało aktywowane. Możesz się zalogować.");
    }

    if (params.has("passwordReset")) {
      setSubmitInfo("Hasło zostało zresetowane. Możesz się zalogować.");
    }

    if (params.has("email")) {
      const email = params.get("email")!;
      setFormValues((prevState) => ({
        ...prevState,
        email,
      }));
    }

    if (params.has("returnUrl")) {
      const returnUrl = params.get("returnUrl")!;
      setFormValues((prevState) => ({
        ...prevState,
        returnUrl,
      }));
    }

    if (params.has("userNotActive")) {
      setSubmitError("Konto jest nieaktywne");
    }

    if (params.has("invalidCredentials")) {
      setSubmitError("Błędny e-mail lub hasło");
    }

    if (params.has("emailNotConfirmed")) {
      setSubmitError("E-mail nie został potwierdzony");
      setEmailNotConfirmed(true);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (!formValues.email && !formValues.password) {
      return;
    }

    validate();
  }, [formValues]);

  useEffect(() => {
    if (
      !isValidating &&
      formValues.password &&
      formValues.email &&
      Object.values(errors).some((x) => x.includes("jest wymagane"))
    ) {
      validate();
    }
  }, [formValues, isValidating, errors]);

  return (
    <Box sx={{ width: "100%", marginTop: "60%" }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">Logowanie</Typography>
        <Typography sx={{ color: colors.black40 }}>
          Zaloguj się do swojego konta
        </Typography>

        {submitError && (
          <Typography sx={{ color: "red" }}>{submitError}</Typography>
        )}
        {submitInfo && (
          <Typography sx={{ color: "green" }}>{submitInfo}</Typography>
        )}
        {emailNotConfirmed && (
          <Button
            disabled={isEmailSending}
            onClick={handleResendConfirmation}
            variant="contained"
            type="submit"
            color={isClient ? "primary" : "secondary"}
            sx={{
              width: "100%",
              color: "white",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            Wyślij ponownie link aktywacyjny
          </Button>
        )}
      </Box>
      <form
        action={`${env_urls.api.uri}Identity/Account/LoginExt`}
        method="POST"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 3,
            width: "100%",
          }}
        >
          <TextField
            type="hidden"
            value={formValues.returnUrl}
            name="returnUrl"
            variant="outlined"
            sx={{ display: "none" }}
          />
          <TextField
            size="small"
            type="email"
            value={formValues.email}
            onChange={handleChange}
            name="email"
            placeholder="np. jankowalski@gmail.com"
            label="E-mail lub nazwa użytkownika"
            variant="outlined"
            sx={{ width: "100%" }}
            error={!!errors.email}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              "data-cy": "email-input",
            }}
          />

          {errors.email && (
            <FormHelperText data-cy="email-error-message">
              {errors.email}
            </FormHelperText>
          )}

          <TextField
            size="small"
            type="password"
            value={formValues.password}
            onChange={handleChange}
            name="password"
            placeholder="Twoje hasło"
            label="Hasło"
            variant="outlined"
            sx={{ width: "100%" }}
            error={!!errors.password}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              "data-cy": "password-input",
            }}
          />
          {errors.password && (
            <FormHelperText>{errors.password}</FormHelperText>
          )}
          <Button
            data-cy="login-button"
            disabled={!!errors.email || !!errors.password}
            variant="contained"
            onClick={(e) => {
              if (!formValues.email || !formValues.password) {
                e.preventDefault();
                validate();
              }
            }}
            type="submit"
            color={isClient ? "primary" : "secondary"}
            sx={{
              width: "100%",
              color: "white",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            Zaloguj się
          </Button>
          <Link
            href="/forgot-password"
            sx={{ textAlign: "center" }}
            data-cy="forgot-password-link"
          >
            <Typography variant="body2">Zapomniałeś hasła?</Typography>
          </Link>
          <Link href="/register" sx={{ textAlign: "center" }}>
            <Typography variant="body2">
              Nie masz jeszcze konta? Zarejestruj się
            </Typography>
          </Link>
        </Box>
      </form>
    </Box>
  );
};
