import { createTheme } from "@mui/material";
import { colors } from "./colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primaryMain,
      light: colors.primaryLight,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondaryMain,
      light: colors.secondaryLight,
      dark: colors.secondaryDark,
    },
    success: {
      main: colors.secondaryMain,
    },
  },
  typography: {
    fontFamily: "VisbyCF-Medium, sans-serif",
    h1: {
      color: colors.secondaryDark,
      fontSize: "48px",
      fontWeight: 800,
      fontFamily: "VisbyCF-ExtraBold",
    },
    h2: {
      color: colors.secondaryDark,
      fontSize: "42px",
      fontWeight: 800,
      fontFamily: "VisbyCF-ExtraBold",
    },
    h3: {
      color: colors.secondaryDark,
      fontSize: "36px",
      fontWeight: 800,
      fontFamily: "VisbyCF-ExtraBold",
    },
    h4: {
      color: colors.secondaryDark,
      fontSize: "32px",
      fontWeight: 400,
      fontFamily: "VisbyCF-Medium",
    },
    h5: {
      color: colors.secondaryDark,
      fontSize: "24px",
      fontWeight: 400,
      fontFamily: "VisbyCF-Medium",
    },
    h6: {
      color: colors.secondaryDark,
      fontSize: "20px",
      fontWeight: 800,
      fontFamily: "VisbyCF-ExtraBold",
    },
    subtitle1: {
      color: colors.secondaryDark,
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "VisbyCF-Medium",
    },
    subtitle2: {
      color: colors.secondaryDark,
      fontSize: "16px",
      fontWeight: 800,
      fontFamily: "VisbyCF-ExtraBold",
    },
    body1: {
      color: colors.secondaryDark,
      fontSize: "16px",
      fontWeight: 400,
    },
    body2: {
      color: colors.secondaryDark,
      fontSize: "14px",
      fontWeight: 400,
    },
    button: {
      color: colors.secondaryDark,
      fontFamily: "VisbyCF-Medium",
    },
  },
});
