﻿import * as axios from "axios";
// @ts-ignore
import { setEnvVariables } from "./config/env_configs/local.js";

if (import.meta.env.MODE === "development") {
    setEnvVariables();
}

// ----------------
// API
// @ts-ignore
export const env_urls = window.APP_URLS;
// @ts-ignore
export const settings = window.SETTINGS;

export const client = new axios.Axios({
    // @ts-ignore
    baseURL: env_urls.api.uri,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
});